import {Dialog} from 'primereact/dialog'

const CustomModal = ({
    id = null,
    children,
    show,
    fullScreen = false,
    onShow = null,
    onHide,
    title,
    titleHelpText = '',
    showHeader = true,
    footerComponent = null,
    customHeaderElement = null,
    // fullScreen = false,
    borderRadius = '20px',
    maxWidth = 750,
    borderColor = null,
    zIndex = 0,
    showline = true,
    className = null,
    backgroundColor = 'cmwhite',
    showClose = true,
    style = null,
    headerBorderRadius = '20px',
    dismissableMask = false,
    ladder = false,
}) => {
    return (
        <Dialog
            onShow={onShow}
            id={id}
            contentClassName={`bg-${backgroundColor}`}
            className={`${fullScreen ? '' : `mw-${maxWidth}px`} w-100 p-0 m-0 ${
                borderColor ? 'border' : null
            } border-${borderColor} border-1 ${className} shadow-lg`}
            style={{
                borderRadius: borderRadius,
                padding: 0,
                margin: 0,
                ...style,
            }}
            visible={show}
            maximized={fullScreen}
            onHide={onHide}
            closable={false}
            closeOnEscape
            headerStyle={{
                borderTopLeftRadius: headerBorderRadius,
                borderTopRightRadius: headerBorderRadius,
            }}
            contentStyle={{
                position: 'relative',
                padding: ladder && 0,
                borderBottomLeftRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
            }}
            baseZIndex={zIndex}
            modal
            blockScroll={true}
            header={() =>
                customHeaderElement ? (
                    customHeaderElement
                ) : showHeader ? (
                    <div className='w-100 m-0 p-0' >
                        <div className='d-flex justify-content-between align-items-center ps-10 pe-5 py-2'>
                            <div
                                style={{
                                    fontSize: '16px',
                                    color: '#0D1821',
                                    fontWeight: '700',
                                }}
                                className='w-100 d-flex justify-content-center align-items-center'
                            >
                                {title}
                            </div>
                            {showClose ? (
                                <div
                                    id='Close_Modal'
                                    className={`bi bi-x-circle text-${
                                        borderColor ?? 'cmGrey800'
                                    } fs-1 p-0 m-0 cursor-pointer text-hover-danger`}
                                    onClick={onHide}
                                />
                            ) : (
                                <div></div>
                            )}
                        </div>
                        {showline ? <hr className='mt-0 mb-5 text-cmGrey900' /> : null}
                    </div>
                ) : null
            }
            headerClassName={`bg-${backgroundColor} ${showHeader ? 'p-0 m-0' : 'p-0'}`}
            footer={footerComponent ?? null}
            dismissableMask={dismissableMask}
        >
            {children}
        </Dialog>
    )
}
export default CustomModal
