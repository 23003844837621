import "./App.css";
import Toastify from "./toastify";
import Router from "./routes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { NetworkCheckScreen } from "./hooks/useNetworkStatus";

function App() {
    return (
        <>
           <NetworkCheckScreen />
            <Router />
            <Toastify />
            <ConfirmDialog />
        </>
    );
}

export default App;
