import { v4 as uuidv4 } from "uuid";

export const ORIGIN_BASE_URL = window.location.origin?.includes('localhost') ? "https://classicquotes.co.za" : window.location.origin
export const BASE_URL = `${ORIGIN_BASE_URL}/classic_intranet_backend/public/api`;
export const DevConfig = {
    ENABLE_CONSOLE_LOGS: true,
};
export const IMAGE_BASE_URL = `${ORIGIN_BASE_URL}/classic_intranet_backend/public`;

export const ROLES = {
    ADMIN: 1,
    SALESMAN: 2,
};

export const PREDEFINED_SECTIONS = [
    {
        sections_name: "Bedroom 1",
        products: [],
        sub_sections: [],
    },
    {
        sections_name: "Bedroom 2",
        products: [],
        sub_sections: [],
    },
    {
        sections_name: "Bathroom",
        products: [],
        sub_sections: [],
    },
    {
        sections_name: "Uncategorized",
        products: [],
    },
];

export const QUOTATIONS_STATUS = [
    { name: "Spec Confirmed", value: "Spec Confirmed" },
    { name: "Active", value: "Active" },
    { name: "On-hold", value: "On-hold" },
    { name: "Order Confirmed", value: "Order Confirmed" },
];
